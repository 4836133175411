import AOS from 'aos';

$(function() {

    // Initialize AOS animations
    AOS.init({ duration: 1000 });

    $(".alohaWrapper").on("click", function() {
        $(this).hide();
        $(".overlayContainer").fadeIn();
    });

    $(".overlayWrapper .aloha").on("click", function () {
        $(".alohaWrapper").fadeIn();
        $(".overlayContainer").fadeOut();
    });

    $(".alohaWrapperD .aloha").on("click", function() {
        $(".overlayContainer").toggleClass('show');
    });
})
